import React, { useState } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useCubeQuery } from "@cubejs-client/react";
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import cubejs from "@cubejs-client/core";
import Map from './Map';
import numeral from "numeral";
import {
  Spin,
  Row,
  Col,
  Layout
} from "antd";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Modal,
  Box
} from "@material-ui/core";

const numberFormatter = item => numeral(item).format("0,0");

const dateFormatter = item => moment(item).format("DD MMM");

const colors = ["#7DB3FF", "#49457B", "#FF7C78", "#7DB3FF", "#49457B", "#FF7C78"];

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    padding: 0
  },
  inner: {
    
  },
  nameContainer: {
    display: "flex",
    alignItems: "baseline"
  },
  status: {
    marginRight: 15
  },
  actions: {
    justifyContent: "flex-end"
  },
}));


const Dashboard = ({ children }) => [
  <Row
    type="flex"
    justify="space-around"
    align="top"
    gutter={24}
    style={{
      marginBottom: 20
    }}
  ></Row>,
  <Row type="flex" justify="space-around" align="top" gutter={24}>
    {children}
  </Row>
];

const DashboardItem = ({ children, title, size, height, onClick }) => (
  <Col span={24} lg={size}>
  <div align="right">
				<button
				  type="button"
				  data-dismiss="alert"
				  aria-label="Close"
				  onClick={onClick}
				  style={{'border-radius': '4px','cursor':'pointer'}}
				>
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
    <Card
      title={title}
      style={{
        marginBottom: "24px"
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};

const renderChart = (Component, options = {}) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;
  
const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TableComponent = props => {

  const { className, query, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [keys, selectedRowKey] = React.useState(false);
  
  const handleClose = () => setOpen(false);

  const tableHeaders = [
    { text: "Album Artist", value: "StreamsPartition.album_artist" }
  ];
  const { resultSet, error, isLoading } = useCubeQuery(query, { cubejsApi });
  if (isLoading) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" /></div>;
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (resultSet) {
    let streams = resultSet.tablePivot();

    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = event => {
      setRowsPerPage(event.target.value);
    };
	const handleOpen = (event, id) => {
		// console.log(id);
		setOpen(true)
		selectedRowKey(id)
	};
    return (
      <Card
        {...rest}
        padding={"0"}
        className={clsx(classes.root, className)} style={{'height':'720px'}}
      >
        <CardContent className={classes.content} style={{'height':'600px','overflow-y':'auto'}}>
            <div className={classes.inner}>
              <Table>
                <TableHead className={classes.head}  style={{'background-color':'lightgray'}}>
                  <TableRow>
                    {tableHeaders.map((item) => (
                      <TableCell key={item.value + Math.random()} 
                                                                 className={classes.hoverable}           
                      >
                        <span>{item.text}</span>

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {streams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(obj => (
                    <TableRow
                      className={classes.tableRow}
					  hover
					  onClick={event => handleOpen(event, obj["StreamsPartition.albumArtist"])}
                      key={obj["StreamsPartition.albumArtist"]}
					   style={{cursor:'pointer'}}
                    >
                      <TableCell>
                        {obj["StreamsPartition.albumArtist"]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={streams.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10]}
          />
        </CardActions>
		<CardActions>
	  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
	  <div className="App">
          <Dashboard>
			<DashboardItem
              height={700}
              title=""
              size={17}
			  onClick={handleClose}
            >
       <Map query={{
  "measures": [],
  "timeDimensions": [],
  "order": {
    "StreamsPartition.albumArtist": "asc"
  },
  "dimensions": [
    "StreamsPartition.geohash",
    "StreamsPartition.userId"
  ],
  "filters": [
    {
      "member": "StreamsPartition.albumArtist",
      "operator": "equals",
      "values": [`${keys}`]
    }
  ]
	   }} />
			   </DashboardItem>
          </Dashboard>
      </div>
      </Modal>
	  </CardActions>
      </Card>
	  
    );
  } else {
    return null
  }
};
 

TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired
};

export default TableComponent;