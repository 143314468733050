import React, { useState, useEffect } from "react";
import "./App.css";
import {
  Row,
  Col,
  Card,
  Layout,
  Spin,
  Statistic,
  DatePicker,
  Radio,
  Switch,
  Menu,
  Icon
} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { useCubeQuery }  from '@cubejs-client/react';
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import moment from "moment";
import numeral from "numeral";
import TableMap from './table_map';
import mainLogo from'./rn.png';

const numberFormatter = item => numeral(item).format("0,0");

const dateFormatter = item => moment(item).format("DD MMM");

const colors = ["#7DB3FF", "#49457B", "#FF7C78", "#7DB3FF", "#49457B", "#FF7C78"];

const Dashboard = ({ children }) => [
  <Row
    type="flex"
    justify="space-around"
    align="top"
    gutter={24}
    style={{
      marginBottom: 20
    }}
  ></Row>,
  <Row type="flex" justify="space-around" align="top" gutter={24}>
    {children}
  </Row>
];

const DashboardItem = ({ children, title, size, height }) => (
  <Col span={24} lg={size}>
    <Card
      title={title}
      style={{
        marginBottom: "24px"
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};


const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});

const renderChart = (Component, options = {}) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;
  
const query_map = {
  "measures": ["StreamsPartition.totaltrackcount"],
  "timeDimensions": [],
  "order": {
	"StreamsPartition.totaltrackcount": "desc"
  },
  "dimensions": [
	"StreamsPartition.albumArtist"
  ],
 "filters": [
    {
      "member": "StreamsPartition.country",
      "operator": "notEquals",
      "values": [""]
    },
	{
	  "member": "StreamsPartition.albumArtist",
	  "operator": "set",
	}
 ]  
};

class App extends React.Component {
	
  render() {

    return (
      <div className="App">
          <Dashboard>
			<DashboardItem
              height={720}
              title="Geo Hash Map"
              size={12}
            >
              <TableMap query={query_map}/>
            </DashboardItem>
          </Dashboard>
      </div>
	  
      
    );
  }
}

export default App;
