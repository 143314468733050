import React, { Component, useState, useEffect } from "react";
import autoBind from "react-autobind";
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { CubeProvider } from '@cubejs-client/react';
import { HashRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import "./App.css";
import {
  Row,
  Col,
  Card,
  Layout,
  Modal,
  Spin,
  Statistic,
  DatePicker,
  Radio,
  Dropdown,
  Button,
  Menu,
  Table,
  Icon
} from "antd";
import "antd/dist/antd.css";
import Track from './component/Track';
import Main from './component/Main';
import Login from './component/Login';
import CustLogin from './component/CustLogin';
import DiscoveryHub from './component/DiscoveryHub';
import Customer from './component/customer';
import Home from './component/Home';
import "./index.css";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { useCubeQuery }  from '@cubejs-client/react';
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import moment from "moment";
import numeral from "numeral";
import mainLogo from'./rn.png';
import useToken from './component/useToken';
import axios from 'axios';

const numberFormatter = item => numeral(item).format("0,0");

const dateFormatter = item => moment(item).format("DD MMM");

const colors = ["#7DB3FF", "#49457B", "#FF7C78", "#7DB3FF", "#49457B", "#FF7C78"];

/* app.use(function (req, res, next) {

    // Website you wish to allow to connect
    res.setHeader('Access-Control-Allow-Origin', '*');

    // Request methods you wish to allow
    res.setHeader('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');

    // Request headers you wish to allow
    res.setHeader('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    // Set to true if you need the website to include cookies in the requests sent
    // to the API (e.g. in case you use sessions)
    res.setHeader('Access-Control-Allow-Credentials', true);

    // Pass to next layer of middleware
    next();
});
 */

const AppLayout = ({ children, setToken, token }) => (
  <Layout>
    <Layout.Header style={{"position": "sticky","top": "0", "z-index": "10"}}>
      <div
        style={{
          float: "left"
        }}
      >
        <img src={mainLogo} style={{
          height: "50px"
        }} />
      </div>
	  <div
        style={{
          float: "right",'font-size': '22px','font-weight': 'bold'
        }}
      >
	  <HashRouter>
	  <nav>
	  {token == 'loggedin' ?  <Link to="/" onClick={() => {setToken('');sessionStorage.removeItem('ceo');sessionStorage.removeItem('indicator');window.location.href="/"}} style={{color:'white'}}>Logout</Link> : <Link to="/CustLogin" onClick={() => {setToken('');sessionStorage.removeItem('customerId');window.customerId='';window.location.href="#/CustLogin";}} style={{color:'white'}}>Logout</Link>
	  }
      </nav>
   </HashRouter> 
      </div>
    </Layout.Header>
    <Layout.Content
      style={{
        padding: "0 25px 25px 25px",
        margin: "25px"
      }}
    >
      {children}
    </Layout.Content>
  </Layout>
);

function App() {

  const { token, setToken } = useToken();
  const [ip, setIP] = useState('');
  
  const ipaddress = [
    {
      ip: "112.133.196.137"
    },
    {
      ip: "213.123.60.58"
    },
    {
      ip: "167.99.182.186"
    },
    {
      ip: "49.207.193.10"
    },
    {
      ip: "49.207.206.10"
    },
    {
      ip: "49.207.206.108"
    },
    {
      ip: "159.203.37.110"
    }
  ];
  
  const ceo = sessionStorage.getItem('ceo');
  const loggedceo = JSON.parse(ceo);
  // console.log(loggedceo);
  
  const indicator = sessionStorage.getItem('indicator');
  const loggedindicator = JSON.parse(indicator);
  
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  };
  
  useEffect( () => {
    getData()
  }, [])
  
  const checkIp = ipaddress.find((address) => address.ip === ip);
  
  if (!checkIp && token != 'Cloudloggedin') {
	  if(token && (ip != '' && ip != undefined)) {
		setToken(''); 
	  }
  }
  
  if(!token) {
    return (
      <div className="App">
		<HashRouter> 
			<Switch>
				<Route path="/CustLogin">
					<CustLogin setToken={setToken} />
				</Route>
				<Route path="/">
					<Login setToken={setToken} />
				</Route>
			</Switch>
		</HashRouter> 
      </div>  
	  
    );
  }else{
	if (token == 'Cloudloggedin') {
		return (
			<div className="App">
				<AppLayout setToken={setToken} token={token}>
					<HashRouter> 
						<Switch>
							<Route path="/Customer">
								<Customer />
							</Route>
							<Route path="/CustLogin">
								<Customer />
							</Route>
							<Route path="/">
								<Customer />
							</Route>
						</Switch>
					</HashRouter> 
				</AppLayout>
			</div>  
		);
	}else{
		if(!loggedceo && !loggedindicator){
			return (
			<div className="App">
					<AppLayout setToken={setToken} token={token} >
						<HashRouter> 
							<Switch>
								<Route path="/Map">
									<Track />
								</Route>
								<Route path="/Hub">
									<Home />
								</Route>
								<Route path="/Home">
									<Home />
								</Route>
								<Route path="/">
									<Home />
								</Route>
							</Switch>
						</HashRouter> 
					</AppLayout>
				</div>  
				);
		}  
		else if(!loggedceo){
			return (
			<div className="App">
					<AppLayout setToken={setToken} token={token} >
						<HashRouter> 
							<Switch>
								<Route path="/Map">
									<Track />
								</Route>
								<Route path="/Hub">
									<Home />
								</Route>
								<Route path="/Home">
									<Main />
								</Route>
								<Route path="/">
									<Home />
								</Route>
							</Switch>
						</HashRouter> 
					</AppLayout>
				</div>  
				);
		  }else if(!loggedindicator){
			  return (
			<div className="App">
					<AppLayout setToken={setToken} token={token} >
						<HashRouter> 
							<Switch>
								<Route path="/Map">
									<Track />
								</Route>
								<Route path="/Hub">
									<DiscoveryHub />
								</Route>
								<Route path="/Home">
									<Home />
								</Route>
								<Route path="/">
									<Home />
								</Route>
							</Switch>
						</HashRouter> 
					</AppLayout>
				</div>  
				);
		  }else{
			return (
				<div className="App">
					<AppLayout setToken={setToken} token={token} >
						<HashRouter> 
							<Switch>
								<Route path="/Map">
									<Track />
								</Route>
								<Route path="/Hub">
									<DiscoveryHub />
								</Route>
								<Route path="/Home">
									<Main />
								</Route>
								<Route path="/">
									<Home />
								</Route>
							</Switch>
						</HashRouter> 
					</AppLayout>
				</div>
			);  
		  }
	}
  }
}
export default App;
