import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import cubejs from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Login.css";
import Logo from'./b2blogo1.png';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { format } from "date-fns";
var md5 = require('md5');

async function loginUser(credentials) {
	const token = {
      token: "Cloudloggedin"
    };
 return token;
}
const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});
const get_query = {
  "measures": [],
  "timeDimensions": [],
  "order": {
    "CustomerLogin.username": "asc"
  },
  "dimensions": [
    "CustomerLogin.username",
    "CustomerLogin.loginHash",
    "CustomerLogin.customerId"
  ],
  "filters": [
    {
      "member": "CustomerLogin.active",
      "operator": "equals",
      "values": [
        "true"
      ]
    },
    {
      "member": "CustomerLogin.deleted",
      "operator": "equals",
      "values": [
        "true"
      ]
    }
  ]
};

const currentday = new Date();
const last7day = new Date(new Date(currentday).setDate(currentday.getDate() - 14));
window.lastmonth = format(new Date(last7day),'Y-MM-dd');
window.today = format(new Date(currentday),'Y-MM-dd');

const get_query1 = {
  "measures": [],
  "timeDimensions": [
	{
	  "dimension": "StreamsPartition.datetimestamp",
	  "dateRange": [window.lastmonth,window.today]
	}
  ],
  "order": {
    "StreamsPartition.datetimestamp": "desc"
  },
  "dimensions": [
    "StreamsPartition.datetimestamp"
  ],
  limit: 1,
  "filters": []
};

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessages, setErrorMessages] = useState({});
  const [ip, setIP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { resultSet:result2, error:error2, isLoading:isLoading2 } = useCubeQuery(get_query1, { cubejsApi });
  const history = useHistory();
  
  if (result2) {
	   let Streams2 = result2.tablePivot();
	   {Streams2.map(obj =>{
		 window.dateUpdate = obj["StreamsPartition.datetimestamp"];
		 sessionStorage.setItem('dateUpdate', obj["StreamsPartition.datetimestamp"]);
	})}
  }
  
  const errors = {
    invalid: "invalid username or password"
  }; 
  
  const handleSubmit = async e => {
    e.preventDefault();
	var { uname, pass } = document.forms[0];
	
    // Find user login info

	let loginaccess = '';
    const searchUrl = `https://analytics.routenote.com/clouds/GetCustomer.php?username=`+uname.value+`&password=`+md5(pass.value);
    setIsLoading(true);
	
	axios.get( searchUrl, {} )
		.then( res => {
			loginaccess = res.data.login;
			if (loginaccess == 'true'){
				sessionStorage.setItem('customerId', res.data.customer_id);
				const token = {
				  token: "Cloudloggedin"
				};
				
				window.customerId=res.data.customer_id;
				history.push('/Customer');
				window.location.reload(false);
				setToken(token);
			}else{
				setIsLoading(false);
				setErrorMessages({ name: "invalid", message: errors.invalid });
			}
	}).catch( error => {
		setIsLoading(false);
	});
  }
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
	);
  return(
	<div className="logo app">
	 <img src={Logo} width="325px" className="my-5 custlogin-5" style={{"margin-bottom": "1rem!important","margin-top": "8rem!important"}} /> 
	
      <div className="login-form my-5" >
        <div className="title">Login</div>
		<div className="form">{renderErrorMessage("invalid")}
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" className="form-control" required style={{"border": "1px solid #dee2e6"}} />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" className="form-control" required style={{"border": "1px solid #dee2e6"}} />
        </div>
        <div className="button-container">
		  {isLoading ? <button disabled="disabled" className="dummy-button" style={{"opacity": "0.8"}}><CircularProgress size="1rem" thickness={8} color="secondary" style={{"color": "white",marginRight: "5px"}} />  Login</button> : <input type="submit" value="Login" />}
        </div>
      </form>
    </div>
	 </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};