import React, { useState } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useCubeQuery } from "@cubejs-client/react";
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import cubejs from "@cubejs-client/core";
import numeral from "numeral";
import {
  Spin,
  Row,
  Col,
  Layout
} from "antd";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Modal,
  Box
} from "@material-ui/core";
import { format } from "date-fns";
import Country from './country';
import SourceType from './table_sourcetype';

const numberFormatter = item => numeral(item).format("0,0");

const dateFormatter = item => moment(item).format("DD MMM");

const colors = ["#7DB3FF", "#49457B", "#FF7C78", "#7DB3FF", "#49457B", "#FF7C78"];

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    padding: 0
  },
  inner: {
    
  },
  nameContainer: {
    display: "flex",
    alignItems: "baseline"
  },
  status: {
    marginRight: 15
  },
  actions: {
    justifyContent: "flex-end"
  },
}));


const Dashboard = ({ children }) => [
  <Row
    type="flex"
    justify="space-around"
    align="top"
    gutter={24}
    style={{
      marginBottom: 20
    }}
  ></Row>,
  <Row type="flex" justify="space-around" align="top" gutter={24}>
    {children}
  </Row>
];

const DashboardItem = ({ children, title, size, height }) => (
  <Col span={24} lg={size} style={{top: "90px"}}>
    <Card
      title={title}
      style={{
        marginBottom: "24px"
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};

const lineRender = ({ resultSet, yFormatter }) => (

  <Chart
    padding={[ 29, 35, 27, 39]}
    height={300}
    data={stackedChartData(resultSet)}
    forceFit
	style={{padding: '17px'}}
  >
  <div style={{padding: '10px', 'font-size': '20px', 'font-weight': '500'}}>RN Total Stream Count(This Month)</div>
    <Axis
      name="x"
      label={{
        formatter: dateFormatter
      }}
    />
    <Axis
      label={{
        formatter: yFormatter || numberFormatter
      }}
      name="measure"
    />
    <Tooltip
      crosshairs={{
        type: "y"
      }}
    />
    <Geom
      type="line"
      position={`x*measure`}
      size={2}
	  color={["color", colors]}
    />
  </Chart>
);
const stackedChartData = resultSet => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Math.round(m)
      }))
    )
    .reduce((a, b) => a.concat(b));
  return data;
};
const renderChart = (Component, options = {}) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;
const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TableComponent = props => {
  const { className, query, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [streamopen, streamsetOpen] = React.useState(false);
  const [keys, selectedRowKey] = React.useState(false);
  const [dateupdate, selectedRowDate] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const streamHandleClose = () => streamsetOpen(false);

 const tableHeaders = [
    { text: "Date", value: "CountriesStreams.date" },
    { text: "Streams", value: "CountriesStreams.streams" },
    { text: "Source", value: "" }
  ];
  
	const currentYear = new Date();
	const curr = new Date(props.year+'-'+props.month+'-01'); // get current date
	window.monthstart = format(new Date(curr.getFullYear(), curr.getMonth(), 1),'Y-MM-dd');
	window.monthend = format(new Date(curr.getFullYear(), curr.getMonth() + 1, 0),'Y-MM-dd');
	window.total = '';
	const thismonth_count = {
		"measures": [
			"CountriesStreams.streams"
		],
		"filters": [
			{
				"member": "CountriesStreams.datetime",
				"operator": "inDateRange",
				"values": [
				window.monthstart,
				window.monthend
				]
			}
		]
	};
	const { resultSet:result1, error:error1, isLoading:isLoading1 } = useCubeQuery(thismonth_count, { cubejsApi });
  
	if (result1) {
	   let Streams1 = result1.tablePivot();
		{Streams1.map(obj =>{
			window.total = obj["CountriesStreams.streams"];
		})}
	}
  const { resultSet, error, isLoading } = useCubeQuery(query, { cubejsApi });
  if (isLoading) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" /></div>;
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  
  if (resultSet) {
	  
    let Streams = resultSet.tablePivot();
	 
    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = event => {
      setRowsPerPage(event.target.value);
    };
	const handleOpen = (event, id) => {
		console.log(id);
		setOpen(true)
		selectedRowKey(moment(id).format("YYYY-MM-DD"))
	};
	const streamHandleOpen = (event, id) => {
		streamsetOpen(true)
		selectedRowDate(id)
	};
    return (
      <Card
        {...rest}
        padding={"0"}
        className={clsx(classes.root, className)} style={{'height':'720px'}}>
		<div style={{padding: '10px 0px', 'font-size': '17px', 'font-weight': '500'}}>Total: {window.total}</div>
        <CardContent className={classes.content} style={{'height':'600px','overflow-y':'auto'}}>
            <div className={classes.inner}>
              <Table>
                <TableHead className={classes.head}  style={{'background-color':'lightgray',"position": "sticky","top":" 0"}}>
                  <TableRow>
                    {tableHeaders.map((item) => (
                      <TableCell key={item.value + Math.random()} className={classes.hoverable} >
                        <span>{item.text}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Streams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(obj => (
                    <TableRow
                      className={classes.tableRow}
                      key={obj["CountriesStreams.date"]}
					   style={{cursor:'pointer'}} >
                      <TableCell
					  hover
					  onClick={event => handleOpen(event, obj["CountriesStreams.date"])}>
                        { moment(obj["CountriesStreams.date"]).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
					  hover
					  onClick={event => handleOpen(event, obj["CountriesStreams.date"])}>
                        {obj["CountriesStreams.streams"]}
                      </TableCell>
					  <TableCell>
                        <a href="#/hub" onClick={event => streamHandleOpen(event, obj["CountriesStreams.date"])}>View Source(USA)</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={Streams.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10]}
          />
        </CardActions>
		<CardActions>
	  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
	  <div className="App">
          <Dashboard>
			<DashboardItem
              height={720}
              title=""
              size={12}>
			<div style={{padding: '10px', 'font-size': '20px', 'font-weight': '500'}}>{moment(keys).format("DD-MM-YYYY")}
			<div style={{float: 'right'}}>
				<button
				  type="button"
				  data-dismiss="alert"
				  aria-label="Close"
				  onClick={handleClose}
				  style={{'border-radius': '4px','cursor':'pointer'}}
				>
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			</div>
			<Country query={{
			  "measures": [
				"CountriesStreams.streams"
			  ],
			  "timeDimensions": [],
			  "order": {
				"CountriesStreams.streams": "desc"
			  },
			  "dimensions": [
				"CountriesStreams.name",
				"CountriesStreams.iso"
			  ],
			  "filters": [
				{
				  "member": "CountriesStreams.date",
				  "operator": "equals",
				  "values": [`${keys}`]
				}
			  ]
			}} />
			</DashboardItem>
          </Dashboard>
      </div>
      </Modal>
	  </CardActions>
	  <CardActions>
				<Modal
				open={streamopen}
				onClose={streamHandleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{'z-index': '0'}}>
					<div className="App">
						<Dashboard>
							<DashboardItem
							height={720}
							size={12}>
								<div style={{padding: '10px', 'font-size': '20px', 'font-weight': '500'}}>Breakdown By Source(USA)
								<div style={{float: 'right'}}>
									<button
									  type="button"
									  data-dismiss="alert"
									  aria-label="Close"
									  onClick={streamHandleClose}
									  style={{'border-radius': '4px','cursor':'pointer'}}
									>
									  <span aria-hidden="true">&times;</span>
									</button>
								</div>
								</div>
								<SourceType query={{
								  "dimensions": [
									"StreamsPartition.source"
								  ],
								  "timeDimensions": [
									{
									  "dimension": "StreamsPartition.datetimestamp",
									  "dateRange": [dateupdate]
									}
								  ],
								  "order": {
									"StreamsPartition.totaltrackcount": "desc"
								  },
								  "measures": [
									"StreamsPartition.totaltrackcount"
								  ]
								}}  streamdate={dateupdate} />
							</DashboardItem>
						</Dashboard>
					</div>
				</Modal>
			</CardActions>
      </Card>
	  
    );
  } else {
    return null
  }
};
 

TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired
};

export default TableComponent;