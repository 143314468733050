import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import cubejs from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Login.css";
import Logo from'./logo-gray.png';
import axios from 'axios';
var md5 = require('md5');

async function loginUser(credentials) {
	const token = {
		token: "loggedin"
	};
	return token;
}
const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});
const get_query = {
  "measures": [],
  "timeDimensions": [],
  "order": {
    "Users.username": "asc"
  },
  "dimensions": [
    "Users.username",
    "Users.loginHash"
  ],
  "filters": [
    {
      "member": "Users.active",
      "operator": "equals",
      "values": [
        "true"
      ]
    },
    {
      "member": "Users.deleted",
      "operator": "equals",
      "values": [
        "true"
      ]
    }
  ]
};
export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessages, setErrorMessages] = useState({});
  const [ip, setIP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const ipaddress = [
    {
      ip: "112.133.196.137"
    },
    {
      ip: "213.123.60.58"
    },
    {
      ip: "167.99.182.186"
    },
    {
      ip: "49.207.193.10"
    },
    {
      ip: "49.207.206.10"
    },
    {
      ip: "49.207.206.108"
    },
    {
      ip: "159.203.37.110"
    }
  ];

  const errors = {
    invalid: "invalid username or password"
  }; 
  
  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }
  
  useEffect( () => {
    getData()
  }, [])
  
  const handleSubmit = async e => {
    e.preventDefault();
	var { uname, pass } = document.forms[0];

	let loginaccess = '';
	const searchUrl = `https://analytics.routenote.com/clouds/GetUser.php?username=`+uname.value+`&password=`+md5(pass.value);
    setIsLoading(true);

	axios.get( searchUrl, {} )
		.then( res => {
			loginaccess = res.data.login;
			if (loginaccess == 'true'){
				const checkIp = ipaddress.find((address) => address.ip === ip);
				if (checkIp) {
					const token = {
						token: "loggedin"
					};
					setToken(token);
					setIsLoading(false);
				}else{
					// Ip not match
					setIsLoading(false);
					setErrorMessages({ name: "invalid", message: '' });
				}
			}else{
				setIsLoading(false);
				setErrorMessages({ name: "invalid", message: errors.invalid });
			}
	}).catch( error => {
		setIsLoading(false);
	});
  }
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
	);
  return(
	<div className="logo app">
		<img src={Logo} width="225px" className="my-5" />
	
      <div className="login-form">
        <div className="title">Login</div>
		<div className="form">{renderErrorMessage("invalid")}
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" className="form-control" required style={{"border": "1px solid #dee2e6"}} />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" className="form-control" required style={{"border": "1px solid #dee2e6"}} />
        </div>
        <div className="button-container">
		  {isLoading ? <button disabled="disabled" className="dummy-button" style={{"opacity": "0.8"}}><CircularProgress size="1rem" thickness={8} color="secondary" style={{"color": "white",marginRight: "5px"}} />  Login</button> : <input type="submit" value="Login" />}
        </div>
      </form>
    </div>
	 </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
