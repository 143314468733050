import React, { useState, useEffect } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useCubeQuery } from "@cubejs-client/react";
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import cubejs from "@cubejs-client/core";
import Map from './Map';
import numeral from "numeral";
import {
  Spin,
  Row,
  Col,
  Layout
} from "antd";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Modal,
  Box
} from "@material-ui/core";
import { format } from "date-fns";
import { FaInfoCircle } from "react-icons/fa";
import axios from 'axios';

const numberFormatter = item => numeral(item).format("0,0");

const dateFormatter = item => moment(item).format("DD MMM");

const colors = ["#7DB3FF", "#49457B", "#FF7C78", "#7DB3FF", "#49457B", "#FF7C78"];

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    padding: 0
  },
  inner: {
    
  },
  nameContainer: {
    display: "flex",
    alignItems: "baseline"
  },
  status: {
    marginRight: 15
  },
  actions: {
    justifyContent: "flex-end"
  },
}));


const Dashboard = ({ children }) => [
  <Row
    type="flex"
    justify="space-around"
    align="top"
    gutter={24}
    style={{
      marginBottom: 20
    }}
  ></Row>,
  <Row type="flex" justify="space-around" align="top" gutter={24}>
    {children}
  </Row>
];

const DashboardItem = ({ children, title, size, height, onClick }) => (
  <Col span={24} lg={size} style={{top: "90px"}}>
 <Card
      title={title}
      style={{
        marginBottom: "24px"
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};
const lineRender = ({ resultSet, yFormatter }) => (
	<Chart
	padding={[ 29, 35, 27, 39]}
	height={300}
	data={stackedChartData(resultSet)}
	forceFit
	style={{padding: '17px'}}>
		
		<Axis
		name="x"
		label={{
		formatter: dateFormatter
		}} />
		<Axis
		label={{ formatter: yFormatter || numberFormatter }}
		name="measure" />
		<Tooltip crosshairs={{ type: "y" }} />
		<Geom
		type="line"
		position={`x*measure`}
		size={2}
		color={["color", colors]}
		/>
	</Chart>
);

const stackedChartData = resultSet => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Math.round(m)
      }))
    )
    .reduce((a, b) => a.concat(b));
  return data;
};
const renderChart = (Component, options = {}) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;
  
const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TableComponent = props => {
	
	window.dateUpdate = "2022-01-01"; 
	window.startMonth = "2022-01-01"; 
	window.endMonth = "2022-01-01"; 

  const { className, query, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [keys, selectedRowKey] = React.useState(false);
  const [artist, getArtists] = React.useState(false);
  
  const handleClose = () => setOpen(false);

  const tableHeaders = [
    { text: "Track Name", value: "StreamsPartition.album_artist" },
    { text: "Album Artist", value: "StreamsPartition.album_artist" },
    { text: "Gender", value: "StreamsPartition.album_artist" },
    { text: "Age Group", value: "StreamsPartition.album_artist" }
  ];
  const { resultSet:result3, error:error3, isLoading:isLoading3 } = useCubeQuery(query, { cubejsApi });
  if (result3) {
		let Streams1 = result3.tablePivot();
		{Streams1.map(obj =>{
			window.dateUpdate = obj["StreamsPartition.datetimestamp"];
		})}

		// const curr = new Date(window.dateUpdate); // get current date
		// window.startMonth = format(new Date(curr.getFullYear(), curr.getMonth(), 1),'Y-MM-dd');
		// window.endMonth = format(new Date(curr.getFullYear(), curr.getMonth() + 1, 0),'Y-MM-dd');
		
		const curr = new Date(window.dateUpdate); // get current date
		const last7day = new Date(new Date(curr).setDate(curr.getDate() - 6));
		window.startMonth = format(new Date(last7day),'Y-MM-dd');
		window.endMonth = format(new Date(curr),'Y-MM-dd');
  }

  const query_map = {
	  "measures": [
		"StreamsPartition.albumTrack",
		"StreamsPartition.maxOfTrack",
		"StreamsPartition.totaltrackcount",
		"StreamsPartition.genderfemale",
		"StreamsPartition.gendermale",
		"StreamsPartition.ageGroup1",
		"StreamsPartition.ageGroup2",
		"StreamsPartition.ageGroup3",
		"StreamsPartition.ageGroup4",
		"StreamsPartition.ageGroup5",
		"StreamsPartition.ageGroup6",
		"StreamsPartition.ageGroup7"
	  ],
	  "timeDimensions": [
		{
		  "dimension": "StreamsPartition.datetimestamp",
		  "dateRange": [window.startMonth,window.endMonth]
		}
	  ],
	  "order": 
	  {
    'StreamsPartition.maxOfTrack': 'desc',
    'StreamsPartition.albumTrack': 'desc',
    'StreamsPartition.albumArtist': 'asc'
  },
	  "dimensions": [
		"StreamsPartition.albumArtist",
		"StreamsPartition.trackName",
		"StreamsPartition.CharttrackId",
		"StreamsPartition.uri"
	  ],
	  limit: 100,
	  // "ungrouped": "true",
	  "filters": [
    {
      "member": "StreamsPartition.uri",
      "operator": "set"
    },
    /* {
      "member": "StreamsPartition.trackpopularity",
      "operator": "gte",
      "values": [
        "50"
      ]
    },
    {
      "member": "StreamsPartition.artistpopularity",
      "operator": "gte",
      "values": [
        "50"
      ]
    }, */
	{
	   "member": "StreamsPartition.country",
	  "operator": "contains",
	  "values": props.country
	}/* ,
    {
      "member": "StreamsPartition.releasedate",
      "operator": "inDateRange",
      "values": [
        "2022-01-01",window.endMonth
      ]
    } */
		  ]
	};	
  const { resultSet, error, isLoading } = useCubeQuery(query_map, { cubejsApi });
  if (isLoading) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" /></div>;
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (resultSet) {
    let streams = resultSet.tablePivot();
	let i = 0;

    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = event => {
      setRowsPerPage(event.target.value);
    };
	
	const getData = async (uri) => {
		const res = await axios.get('http://localhost/GetTrack.php?track_id='+uri)
		getArtists(res.data);
	  };
 
	const handleOpen = (event, id, uri) => {
		getArtists('');
		getData(uri)
		setOpen(true)
		selectedRowKey(id)
	};
	 const loadingwheel = (name) =>
    name === undefined && (
      <div style={{'text-align-last': 'center','height': '0%'}}><CircularProgress color="secondary" /></div>
	);

    return (
      <Card
        {...rest}
        padding={"0"}
        className={clsx(classes.root, className)} style={{'height':'720px'}}
      ><div style={{padding: '10px 0px', 'font-size': '17px', 'font-weight': '500'}}>Emerging and Trending Artist in Last 7 days</div>
        <CardContent className={classes.content} style={{'height':'600px','overflow-y':'auto'}}>
            <div className={classes.inner}>
              <Table>
                <TableHead className={classes.head}  style={{'background-color':'lightgray',"position": "sticky","top":" 0"}}>
                  <TableRow>
                    {tableHeaders.map((item) => (
                      <TableCell key={item.value + Math.random()} 
                                                                 className={classes.hoverable}           
                      >
                        <span>{item.text}</span>

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
				{streams.map(obj => {
					if(obj["StreamsPartition.maxOfTrack"] == 1){
						
					i++;
					}
				  })}
                  {streams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(obj => {
					  let uri = obj["StreamsPartition.uri"].split(':');
					  const ageGroups = [
						{ name: "0-17", value: obj["StreamsPartition.ageGroup1"] },
						{ name: "18-22", value: obj["StreamsPartition.ageGroup2"] },
						{ name: "23-27", value: obj["StreamsPartition.ageGroup3"] },
						{ name: "28-34", value: obj["StreamsPartition.ageGroup4"] },
						{ name: "35-44", value: obj["StreamsPartition.ageGroup5"] },
						{ name: "45-59", value: obj["StreamsPartition.ageGroup6"] },
						{ name: "60-150", value: obj["StreamsPartition.ageGroup7"]}
					];
					const numDescending = ageGroups.sort((a, b) => b.value - a.value);
					let mpercentage = Math.round((obj["StreamsPartition.gendermale"]/obj["StreamsPartition.totaltrackcount"])*100);
					let fpercentage = Math.round((obj["StreamsPartition.genderfemale"]/obj["StreamsPartition.totaltrackcount"])*100);
					let agepercentage1 = Math.round((numDescending[0]["value"]/obj["StreamsPartition.totaltrackcount"])*100);
					let agepercentage2 = Math.round((numDescending[1]["value"]/obj["StreamsPartition.totaltrackcount"])*100);
					let agepercentage3 = Math.round((numDescending[2]["value"]/obj["StreamsPartition.totaltrackcount"])*100);
					let agepercentage4 = Math.round((numDescending[3]["value"]/obj["StreamsPartition.totaltrackcount"])*100);
					if(obj["StreamsPartition.maxOfTrack"] == 1){
					  return (
                    <TableRow
                      className={classes.tableRow}
                      key={obj["StreamsPartition.CharttrackId"]}
                    >
                      <TableCell  style={{"width": "25%"}}>
                        {obj["StreamsPartition.trackName"]}
                      </TableCell>
                      <TableCell  style={{"width": "25%"}}>
                        {obj["StreamsPartition.albumArtist"]}
                      </TableCell>
					  <TableCell>
                        Male - {mpercentage}%<br/>Female - {fpercentage}%
                      </TableCell>
					  <TableCell>
                        {numDescending[0]["name"]} - {agepercentage1}%<br/>{numDescending[1]["name"]} - {agepercentage2}%<br/>{numDescending[2]["name"]} - {agepercentage3}%<br/>{numDescending[3]["name"]} - {agepercentage4}%
                      </TableCell>
                    </TableRow>
				  )}})}
                </TableBody>
              </Table>
            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={i}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10]}
          />
        </CardActions>
		<CardActions>
				<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{'z-index': '4'}}>
					<div className="App">
						<Dashboard>
							<DashboardItem
							height={440}
							size={12}>
								<div style={{float: 'right'}}>
									<button
									type="button"
									data-dismiss="alert"
									aria-label="Close"
									onClick={handleClose}
									style={{'border-radius': '4px','cursor':'pointer'}}>
									<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div style={{padding: '18px', 'font-size': '20px', 'font-weight': '500', 'float':'left'}}>Discovery Hub</div>
								<QueryRenderer query={{
									"measures": [
										"StreamsPartition.dailycount"
									],
									"timeDimensions": [{
										"dimension": "StreamsPartition.datetimestamp",
										"granularity": "week",
										"dateRange": props.datestring
									}],
									"order": {
										"StreamsPartition.dailycount": "desc"
									},
									"dimensions": [
										"StreamsPartition.trackName"
									],
									"filters": [{
										"member": "StreamsPartition.CharttrackId",
										"operator": "equals",
										"values": [`${keys}`]
									}]
									}} cubejsApi={cubejsApi} render={renderChart(lineRender)}/>
							</DashboardItem>
						</Dashboard>
					</div>
				</Modal>
			</CardActions>
      </Card>
	  
    );
  } else {
    return null
  }
};
 

TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired
};

export default TableComponent;