import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import {
  Row,
  Col,
  Card,
  Layout,
  Spin,
  Statistic,
  DatePicker,
  Radio,
  Switch,
  Menu,
  Icon
} from "antd";
import "antd/dist/antd.css";
import "./index.css";
import PropTypes from "prop-types";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import { useCubeQuery }  from '@cubejs-client/react';
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import moment from "moment";
import numeral from "numeral";
import TableHub from './table_hub';
import ceo from './ceo.jpg';
import indicator from './indicator.png';
import { useHistory } from 'react-router-dom';
var md5 = require('md5');

const numberFormatter = item => numeral(item).format("0,0");

const dateFormatter = item => moment(item).format("DD MMM");

const colors = ["#7DB3FF", "#49457B", "#FF7C78", "#7DB3FF", "#49457B", "#FF7C78"];

const Dashboard = ({ children }) => [
  <Row
    type="flex"
    justify="space-around"
    align="top"
    gutter={24}
    style={{
      marginBottom: 20
    }}
  ></Row>,
  <Row type="flex" justify="space-around" align="top" gutter={24}>
    {children}
  </Row>
];

const DashboardItem = ({ children, title, size, height, bgimage }) => (
  <Col span={24} lg={size}>
    <Card
      title={title}
	  headStyle={{ backgroundColor: '#1345ac',"font-weight": "bold","font-family": "Arial", color: '#ffffff', fontSize: '22px',"border-bottom": "1px solid rgb(93, 99, 255)","text-align": "center" }}
	  bodyStyle={{ background: `url(${bgimage})`, "background-size": "cover","background-repeat": "no-repeat","background-position-x": "50%"}}
      style={{
        marginBottom: "24px"
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};


const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});

const renderChart = (Component, options = {}) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;
const get_query = {
  "measures": [],
  "timeDimensions": [],
  "order": {
    "Users.ceoPasscode": "asc"
  },
  "dimensions": [
    "Users.ceoPasscode",
  ],
  "filters": [
    {
      "member": "Users.active",
      "operator": "equals",
      "values": [
        "true"
      ]
    },
    {
      "member": "Users.deleted",
      "operator": "equals",
      "values": [
        "true"
      ]
    }
  ]
};

const get_query1 = {
  "measures": [],
  "timeDimensions": [],
  "order": {
    "Users.indicatorPasscode": "asc"
  },
  "dimensions": [
    "Users.indicatorPasscode",
  ],
  "filters": [
    {
      "member": "Users.active",
      "operator": "equals",
      "values": [
        "true"
      ]
    },
    {
      "member": "Users.deleted",
      "operator": "equals",
      "values": [
        "true"
      ]
    }
  ]
};

const TableComponent = props => {
	
	const indicatior_passcode = useRef(null);
	const ceo_passcode = useRef(null);
	const history = useHistory();
	// console.log(props.setToken);
	const { resultSet:result1, error:error1, isLoading:isLoading1 } = useCubeQuery(get_query, { cubejsApi });
	let ceologinaccess = [{
      passcode: md5("exe68v38edyv2mt")
    }]; 
	if (result1) {
		let Streams1 = result1.tablePivot();
		{Streams1.map(obj =>{
			ceologinaccess.push({
			passcode: obj["Users.ceoPasscode"],
			});
		})}
	}
	const { resultSet:result, error:error, isLoading:isLoading } = useCubeQuery(get_query1, { cubejsApi });
	let indloginaccess = [{
      passcode: md5("modu7xov65ft6d3")
    }];
	if (result) {
		let Streams = result.tablePivot();
		{Streams.map(obj =>{
			indloginaccess.push({
			passcode: obj["Users.indicatorPasscode"],
			});
		})}
	}
	const handleClick = (e, loginaccess, dashboard) => {
		if (dashboard == 'ceo') {
			const userData = loginaccess.find((user) => user.passcode === md5(ceo_passcode.current.value));
			if (userData) {
				if (userData.passcode == md5(ceo_passcode.current.value)) {
					const passcode = {
					  passcode: "ceo"
					};
					sessionStorage.setItem('ceo', JSON.stringify(passcode));
					history.push('/hub');
					window.location.reload(false);
				}
			}else{
				if (ceo_passcode.current.value != '') {
					alert("Invalid Passcode");
				}
			}
		}
		
		if (dashboard == 'indicator') {
			const userData = loginaccess.find((user) => user.passcode === md5(indicatior_passcode.current.value));
			if (userData) {
				if (userData.passcode == md5(indicatior_passcode.current.value)) {
					const passcode = {
					  passcode: "indicator"
					};
					sessionStorage.setItem('indicator', JSON.stringify(passcode));
					history.push('/home');
					window.location.reload(false);
				}
			}else{
				if (indicatior_passcode.current.value != '') {
					alert("Invalid Passcode");
				}
			}
			
		}
	};
 return (
      <div className="App" id="home_page">
          <Dashboard>
			<DashboardItem
			title="EXECUTIVE DASHBOARD"
			size={9}
			bgimage={ceo}
			>
				<div style={{'display':'flex','justify-content':'center','padding-top':'140px','font-size':'20px'}}>
					<ul>
					 <li>Daily Streams</li>
					 <li>Discovery Hub<br/></li>
					 <li style={{'font-size':'14px','padding-left': "40px","line-height": "0px"}}>[The Miner]</li>
					</ul>
				</div>
				
					<div style={{'display':'flex','justify-content':'center','padding-top':'150px'}}>
						<input type="password" ref={ceo_passcode} name="ceo_passcode" placeholder="Enter passcode"  style={{"border": "1px solid #dee2e6"}} />
					</div>
					<div style={{'display':'flex','justify-content':'center',"margin-top": "20px"}}>
						<input type="submit" value="LOG IN" onClick={event => handleClick(event, ceologinaccess, 'ceo')} />
					</div>
				
			</DashboardItem>
			<DashboardItem
			title="INDICATOR DASHBOARD"
			size={9}
			bgimage={indicator}
			>
				<div style={{'display':'flex','justify-content':'center','padding-top':'140px','font-size':'20px'}}>
					<ul>
						<li>Daily Analytics By Track</li>
						<li>Weekly Analytics By Track</li>
						<li>Monthly Analytics By Track</li>
					</ul>
				</div>
				<div style={{'display':'flex','justify-content':'center','padding-top':'100px'}}>
					<input type="password" ref={indicatior_passcode} name="indicatior_passcode" placeholder="Enter passcode" style={{"border": "1px solid #dee2e6"}} />
				</div>
				<div style={{'display':'flex','justify-content':'center',"margin-top": "20px"}}>
					<input type="submit" value="LOG IN" onClick={event => handleClick(event, indloginaccess, 'indicator')} />
				</div>
			</DashboardItem>
          </Dashboard>
      </div>
	  
      
    );
};
TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired
};

export default TableComponent;
