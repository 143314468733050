import React, { useState } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import Geohash from 'latlon-geohash';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import cubejs from "@cubejs-client/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const cubejsApi = cubejs(process.env.REACT_APP_CUBEJS_TOKEN, {
  apiUrl: process.env.REACT_APP_API_URL
});

const MyComponent = props => {
	const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAtV2UUjUdQk3CY_WseQrYvgoPZj09NY2o'
  });
	const { query } = props;
	const { resultSet, error, isLoading } = useCubeQuery(query, { cubejsApi });
  if (isLoading) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" /></div>;
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (resultSet) {
	   let device_locations = resultSet.tablePivot();
  

  const containerStyle = {
	  width: '100%',
	  height: '100%'
	};
	

const center = { lat:0, lng: 0 };

  return isLoaded ? (
      <GoogleMap style={{width:'100%'}}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={2}
      >
        {device_locations.map((device_locations, i) => {
			// let latlon = Geohash.decode('te7ud');
			if(device_locations["StreamsPartition.geohash"] != ''){
			let latlon = Geohash.decode(device_locations["StreamsPartition.geohash"]);
			// console.log(device_locations["StreamsPartition.albumArtist"]);
        let lat = parseFloat(latlon.lat, 10);
        let lng = parseFloat(latlon.lon, 10);
        let name = "User ID : "+device_locations["StreamsPartition.userId"];

        return (
          <Marker
            position={{ lat: lat, lng: lng }}
            title={name}
          />
        );
		}
      })}
      </GoogleMap>
  ) : <></>
}else {
    return null
  }
};

export default React.memo(MyComponent)